const ROUTES = {
  "/": {
    "name": "HomePage140ace5f46864837Be02728caddb31ea",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages843fcff3Bd8f4500Bcdc4fee54602015",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageCe1d497009c14c57B42bF34b5b96bf13",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPage2717fcd276864d0bBfa25737127e53a0",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage207edd11188d433084873cb94f2196c5",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage205d85ef01c94aa0A932F91f59964e61",
    "type": "HTML",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages2118986497b74c79B73a2b30895a636f",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesE6b9093aCae949c0972cB9a0bc31d50d",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPageF69c2f28265e43df872470a5195e7f0b",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesB3e55d03318445db8cf325aec7345d39",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;